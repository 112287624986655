<template lang="">
  <div>
    <b-card>
      <basetable
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :is-view="false"
      />
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import {
  BCard
} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard
  },
  data() {
    return {
      dataurl:"/maintenance-interval",
      baseroute:"operasional-maintenance-interval",
      title:"Interval KM Perawatan",
      fields: [
        { key: 'distance', label: 'Interval', sortable: true }
      ],
    }
  },
}
</script>
<style lang="">

</style>
